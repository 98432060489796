















































































































































































































































































































































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import draggable from "vuedraggable";
import { v4 as uuid } from "uuid";
import useRegistrationForm, {
  FormGroup,
  FormFieldType,
  FormGroupType,
} from "@/use/registrationForm";
import useMisc from "@/use/misc";

interface FormError {
  error: string;
}

export default defineComponent({
  components: {
    draggable,
    OFieldEditor: () =>
      import(
        "@/components/organisms/globalTools/registrationForm/o-field-editor.vue"
      ),
  },

  props: {
    type: {
      type: String,
      required: false,
      default: "add",
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup(props, { root }) {
    const { availableFields, fieldTypes, isFieldValid } = useRegistrationForm({
      root,
    });
    const { toCamelCase } = useMisc({ root });

    const axiosInstance = computed(
      () => root.$store.getters["api/getInstance"] as AxiosInstance
    );

    const form = ref<any>(null);

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      valid: false,
      drag: false,
      validator: {
        enabled: false,
        progress: 0,
        message: "",
        error: "",
      },
    });

    const model = reactive<{
      name: string;
      groups: FormGroup[];
    }>({
      name: "",
      groups: [],
    });

    const rules: {
      [key: string]: (v: any) => boolean | string;
    } = {
      required: (v) => !!v || `${root.$t("layout.misc.required")}`,
    };

    const dragOptions = {
      animation: 200,
      disabled: false,
      ghostClass: "ghost",
      scrollSensitivity: 200,
      forceFallback: true,
    };

    const checklist = computed((): {
      [key in FormFieldType]?: {
        isAdded: boolean;
        isAddedOnce: boolean;
      };
    } => {
      const checklistFields: FormFieldType[] = [
        "firstName",
        "lastName",
        "email",
        "repeatEmail",
      ];

      const result: {
        [key in FormFieldType]?: {
          isAdded: boolean;
          isAddedOnce: boolean;
        };
      } = {};

      for (const field of checklistFields) {
        result[field] = {
          isAdded: !!model.groups.find((g) =>
            g.fields.find((f) => f.type === field)
          ),
          isAddedOnce:
            model.groups
              .map((g) =>
                g.fields.filter((f) => f.type === field).map((f) => f.type)
              )
              .flat().length === 1,
        };
      }

      return result;
    });

    const createGroup = (type: FormGroupType = "fields") => {
      model.groups.push({
        id: uuid(),
        name: "",
        type,
        fields:
          type === "consents"
            ? [
                {
                  id: uuid(),
                  type: "consent",
                  name: "marketingConsent",
                  label:
                    'Wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych w celu marketingu produktów i usług Siemens Healthcare Sp z o.o. (administrator danych) i spółek z jej grupy kapitałowej, w tym również na kierowanie treści marketingowej w oparciu o moje preferencje (<a href="{website}/index.php/gdpr" target="_blank">szczegóły zgody</a>).',
                  options: { required: false, size: 12, protectedName: true },
                },
                {
                  id: uuid(),
                  type: "consent",
                  name: "emailOffersConsent",
                  label:
                    "Wyrażam zgodę na otrzymywanie od Siemens Healthcare Sp. z o.o. drogą elektroniczną na wskazany przeze mnie w formularzu adres e-mail informacji handlowych, dotyczących produktów i usług oferowanych przez Siemens Healthcare Sp. o.o. i jej spółki powiązane, w rozumieniu ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz. U z 2013 r., poz. 1422 ze zm.)",
                  options: { required: false, size: 12, protectedName: true },
                },
                {
                  id: uuid(),
                  type: "consent",
                  name: "phoneOffersConsent",
                  label:
                    "Wyrażam zgodę na otrzymywanie telefonicznych połączeń przychodzących inicjowanych przez Siemens Healthcare Sp. z o.o. w celach handlowych i marketingowych produktów własnych i spółek powiązanych, zgodnie z art. 172 ustawy prawo telekomunikacyjne z dnia 16 lipca 2004 r. (Dz. U. z 2017 r. poz. 1907 z późń. zm.)",
                  options: { required: false, size: 12, protectedName: true },
                },
                {
                  id: uuid(),
                  type: "consent",
                  name: "termsConsent",
                  label:
                    'Akceptuję <a href="{website}/index.php/terms-of-service" target="_blank">regulamin</a> i <a href="{website}/index.php/privacy-policy" target="_blank">politykę prywatności</a> serwisu',
                  options: { required: true, size: 12, protectedName: true },
                },
              ]
            : type === "invoice"
            ? [
                {
                  id: uuid(),
                  type: "invoicePurchaser",
                  name: "invoicePurchaser",
                  label: "Typ nabywcy",
                  options: {
                    required: true,
                    size: 12,
                    items: [
                      { id: uuid(), value: "individual", text: "Indywidualny" },
                      {
                        id: uuid(),
                        value: "institutional",
                        text: "Instytucjonalny",
                      },
                    ],
                  },
                },
                {
                  id: uuid(),
                  type: "invoiceName",
                  name: "invoiceName",
                  label: "Nazwa",
                  options: { required: true, size: 12 },
                },
                {
                  id: uuid(),
                  type: "invoiceStreet",
                  name: "invoiceStreet",
                  label: "Ulica i numer",
                  options: { required: true, size: 12 },
                },
                {
                  id: uuid(),
                  type: "invoicePostalCode",
                  name: "invoicePostalCode",
                  label: "Kod pocztowy",
                  options: { required: true, size: 4 },
                },
                {
                  id: uuid(),
                  type: "invoiceCity",
                  name: "invoiceCity",
                  label: "Miasto",
                  options: { required: true, size: 8 },
                },
                {
                  id: uuid(),
                  type: "invoiceEmail",
                  name: "invoiceEmail",
                  label: "E-mail do faktury",
                  options: { required: false, size: 12 },
                },
                {
                  id: uuid(),
                  type: "invoiceVatNumber",
                  name: "invoiceVatNumber",
                  label: "NIP",
                  options: { required: true, size: 12 },
                },
                {
                  id: uuid(),
                  type: "invoiceConsent",
                  name: "invoiceConsent",
                  label:
                    "Zostałem/am poinformowany/na, że wybranie faktury na osobę fizyczną POWODUJE BRAK MOŻLIWOŚCI jej zmiany na fakturę dla firmy.",
                  options: { required: true, size: 12 },
                },
                {
                  id: uuid(),
                  type: "electronicInvoiceConsent",
                  name: "electronicInvoiceConsent",
                  label: "Zgadzam się na otrzymanie faktury elektronicznej.",
                  options: { required: false, size: 12 },
                },
              ]
            : [],
      });
    };

    const createConsent = (name = "", group: any) => {
      // model.groups.fields.push({ id: uuid(), type: 'consent', name: '', label: '', options: { required: false, size: 12 } })
      if (name === "marketingConsent") {
        group.fields.push({
          id: uuid(),
          type: "consent",
          name,
          label:
            'Wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych w celu marketingu produktów i usług Siemens Healthcare Sp z o.o. (administrator danych) i spółek z jej grupy kapitałowej, w tym również na kierowanie treści marketingowej w oparciu o moje preferencje (<a href="{website}/index.php/gdpr" target="_blank">szczegóły zgody</a>).',

          options: { required: false, size: 12, protectedName: true },
        });
      } else if (name === "emailOffersConsent") {
        group.fields.push({
          id: uuid(),
          type: "consent",
          name,
          label:
            "Wyrażam zgodę na otrzymywanie od Siemens Healthcare Sp. z o.o. drogą elektroniczną na wskazany przeze mnie w formularzu adres e-mail informacji handlowych, dotyczących produktów i usług oferowanych przez Siemens Healthcare Sp. o.o. i jej spółki powiązane, w rozumieniu ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz. U z 2013 r., poz. 1422 ze zm.)",

          options: { required: false, size: 12, protectedName: true },
        });
      } else if (name === "phoneOffersConsent") {
        group.fields.push({
          id: uuid(),
          type: "consent",
          name,
          label:
            "Wyrażam zgodę na otrzymywanie telefonicznych połączeń przychodzących inicjowanych przez Siemens Healthcare Sp. z o.o. w celach handlowych i marketingowych produktów własnych i spółek powiązanych, zgodnie z art. 172 ustawy prawo telekomunikacyjne z dnia 16 lipca 2004 r. (Dz. U. z 2017 r. poz. 1907 z późń. zm.)",

          options: { required: false, size: 12, protectedName: true },
        });
      } else if (name === "termsConsent") {
        group.fields.push({
          id: uuid(),
          type: "consent",
          name,
          label:
            'Akceptuję <a href="{website}/index.php/terms-of-service" target="_blank">regulamin</a> i <a href="{website}/index.php/privacy-policy" target="_blank">politykę prywatności</a> serwisu',

          options: { required: false, size: 12, protectedName: true },
        });
      } else {
        group.fields.push({
          id: uuid(),
          type: "consent",
          name,
          label: "",
          options: { required: false, size: 12, nameProtected: false },
        });
      }
    };

    const createField = (type: FormFieldType) => ({
      id: uuid(),
      type: type,
      name: availableFields.regular.includes(type) ? type : "",
      label: fieldTypes.value[type].defaultLabel,
      options: {
        required: ["firstName", "lastName", "email", "repeatEmail"].includes(
          type
        ),
        items:
          fieldTypes.value[type] && fieldTypes.value[type].hasItems
            ? fieldTypes.value[type].defaultItems || []
            : undefined,
        size: 12,
      },
    });

    const getGroupTypeName = (type?: FormGroupType) => {
      switch (type) {
        case "consents":
          return root.$tc("layout.sidebar.item.tools.terms.title");
        case "invoice":
          return root.$tc("event.panel.company.invoice.data");
        case "services":
          return root.$tc("layout.misc.additionalServices");
        case "accommodation":
          return root.$tc("layout.misc.accommodation");
        default:
        case "fields":
          return root.$tc(
            "panel.globalTools.registrationForm.form.groupOfFields"
          );
      }
    };

    const getGroupTypeIcon = (type?: FormGroupType) => {
      switch (type) {
        case "consents":
          return "mdi-checkbox-multiple-marked-outline";
        case "invoice":
          return "mdi-receipt-text-outline";
        case "services":
          return "mdi-room-service-outline";
        case "accommodation":
          return "mdi-bed-outline";
        default:
        case "fields":
          return "mdi-format-list-bulleted-square";
      }
    };

    const resetValidator = () => {
      state.validator.enabled = false;
      state.validator.progress = 0;
      state.validator.message = "";
    };

    const reset = () => {
      state.success = false;
      model.name = "";
      model.groups = [];

      resetValidator();
      state.validator.error = "";
    };

    /**
     * Form validation
     * @returns {Promise<void>} Validation status
     */
    const validateForm = () =>
      new Promise<void>((resolve, reject) => {
        let valid = true;

        const fields = model.groups.map((g) => g.fields).flat();

        state.validator.enabled = true;

        state.validator.progress = 25;
        state.validator.message = root.$tc("alerts.checkChecklist");

        if (
          !(
            checklist.value.firstName?.isAdded &&
            checklist.value.firstName.isAddedOnce
          ) ||
          !(
            checklist.value.lastName?.isAdded &&
            checklist.value.lastName.isAddedOnce
          ) ||
          !(
            checklist.value.email?.isAdded && checklist.value.email.isAddedOnce
          ) ||
          !(
            checklist.value.repeatEmail?.isAdded &&
            checklist.value.repeatEmail.isAddedOnce
          )
        )
          valid = false;

        setTimeout(() => {
          if (!valid)
            return reject(root.$tc("alert.checklistConditionsNotMet.title"));

          state.validator.progress = 50;
          state.validator.message = root.$tc(
            "alert.checkingUniquenessOfFields.title"
          );

          const usedNames: string[] = [];

          for (const field of fields) {
            usedNames.push(field.name);
          }

          if (new Set(usedNames).size !== fields.length) valid = false;

          setTimeout(() => {
            if (!valid)
              return reject(
                root.$tc("alert.makeSureFieldNamesAreUnique.title")
              );

            state.validator.progress = 75;
            state.validator.message = root.$tc("alert.validatingFields.title");

            for (const field of fields) {
              if (!isFieldValid(field, fields)) valid = false;
            }

            setTimeout(() => {
              if (!valid)
                return reject(root.$tc("alert.checkTheCorectnessOfFields"));

              setTimeout(() => {
                state.validator.progress = 100;
                state.validator.message = root.$tc("alert.formIsCorrect.title");

                setTimeout(resolve, 500);
              }, 500);
            }, 500);
          }, 500);
        }, 500);
      });

    const fetch = () => {
      if (props.type === "edit" && props.id) {
        state.loading = true;

        axiosInstance.value
          .get(`registration-form-template/${props.id}`)
          .then(({ data }) => {
            model.name = data.registrationFormTemplate?.name || "";
            model.groups = data.registrationFormTemplate?.groups || [];

            validateForm()
              .catch((error) => {
                state.loading = false;
                state.validator.error = error;
              })
              .finally(resetValidator);
          })
          .catch((error) => (state.error = error.response.status))
          .finally(() => (state.loading = false));
      }
    };

    onMounted(fetch);
    onMounted(() => nextTick(fetch));

    /**
     * Form submit
     * @async
     */
    const submit = async () => {
      await form.value.validate();

      if (state.valid) {
        state.loading = true;

        validateForm()
          .then(() => {
            const data = {
              name: model.name,
              groups: model.groups || undefined,
            };

            if (props.type === "edit" && props.id) {
              axiosInstance.value
                .put(`registration-form-template/${props.id}`, data)
                .then(() => (state.success = true))
                .catch((error) => (state.error = error.response.status))
                .finally(() => (state.loading = false));
            } else {
              axiosInstance.value
                .post("registration-form-template", data)
                .then(() => (state.success = true))
                .catch((error) => (state.error = error.response.status))
                .finally(() => (state.loading = false));
            }
          })
          .catch((error) => {
            state.loading = false;
            state.validator.error = error;
          })
          .finally(resetValidator);
      } else {
        state.validator.error = root.$tc("alert.correctFormErrors");
      }
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.formAlreadyExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    return {
      uuid,
      form,
      state,
      model,
      rules,
      availableFields,
      dragOptions,
      fieldTypes,
      createGroup,
      createConsent,
      createField,
      getGroupTypeName,
      getGroupTypeIcon,
      reset,
      submit,
      getErrorMessage,
      checklist,
      isFieldValid,
      toCamelCase,
    };
  },
});
